.v-error {
  color: #cf4040;
}
.invalid-feedback {
  display: block;
}
.custom-alert {
  width: 100%;
}
.breadcrumb{
  justify-content: end;
  margin-top: -17px;
}
.commantable_header{
  margin-top: 20px;
  margin-bottom: 15px;
}
.link_color{
  color: #000;
}
.comman_table th{
  padding-left: 1px !important;
}
.comman_card_body{
  background-color: #fff;
  border-radius: 10px;
}
.pagination-btn{
  border: 0 !important;
  font-size: 18px;
    font-weight: 700;
    color: #012;
    align-self: center;
}
.pagination-span{
  margin-right:10px ;
  margin-left: 35px;
}
.pagination-select{
  border: 0 !important;
  background-color: #fff !important;
  margin-top: -7px;
  outline: 0;
}
.pagination-box{
  margin-top: -7px;
  display: flex;
  justify-content: center;
  color: #000;
}
.pagination-num{
  align-self: center;
  padding-right: 15px
}
.pagination-select-box{
  display: flex;
  justify-content: flex-end;
  padding: 0;
  color: #000;
}
.search-box{
  outline: 0;
}
.heddin{
  display: none;
}

.footer{
  height: 60px !important;
    position: relative;
    bottom: 0;
    background-color: #fff;
    /* width: -webkit-fill-available; */
}
.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.layout main {
  flex-grow: 1;
}
